import gsap from "gsap";
import Center from "../Layout/Center";
import { useEffect } from "react";
import { useGSAP } from "@gsap/react";

export function ScreenCut({ children, duration = 3 }) {
  useEffect(() => {
    // TODO: Use as overlay (absolute)
    // Sart off with a loading indicator
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      ".screen > div",
      {
        width: "100%",
      },
      {
        width: "0",
        duration: duration / 3,
      }
    );

    gsap.fromTo(
      ".blocker",
      {
        display: "hidden",
      },
      {
        display: "initial",
        duration: duration / 3,
      }
    );

    gsap.to(".screen", {
      height: "100dvh",
      duration: duration / 3,
      delay: 1,
    });
    gsap.to(".screencut", { display: "hidden", zIndex: -1, delay: 2 });
  }, []);

  const sides = true;
  const screen_anim = !sides ? "w-full" : "";

  return (
    <Center className=" bg-red screencut flex-col justify-between block absolute z-50 left-0 top-0 w-full h-full ">
      <div className="blocker bg-black flex-1 w-full"></div>
      <div
        className={`screen bg-transparent h-32 flex flex-row-reverse ${screen_anim}`}
      >
        <div className="dark bg-black h-full"></div>
      </div>

      <div className="blocker bg-black flex-1 w-full"></div>
      {children}
    </Center>
  );
}
