import { useNavigate } from "react-router-dom";

function ProjectCard({ title, image, desc, link }) {
  const navigate = useNavigate();
  return (
    <div
      key={title}
      className="card w-full h-[400px] p-2 bg-slate-600 flex flex-col justify-around cursor-pointer"
      onClick={() => navigate(link)}
    >
      <div className="title">
      {/* <h2 className="text-3xl">{title}</h2> */}
      </div>
      <div className="screenshot">
        <img src={image} />
      </div>
      <div className="optional-description">
        {/* {desc} */}
        <h2 className="text-2xl">{title}</h2>
      </div>
    </div>
  );
}

export default ProjectCard;
