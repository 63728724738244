import "./App.css";
import { ScreenCut } from "./Components/UIAnimation/Screen";
import { Leva } from "leva";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ToolBar from "./Components/Toolbar";
import { ErrorBoundary } from "react-error-boundary";
import ProjectCard from "./Components/blocks/ProjectCard";

const ENV_MODE = import.meta.env.MODE || "production";
const isProd = ENV_MODE === "production";

function FallBack({ error, resetErrorBoundary }) {
  return (
    <div>
      <p>Somthing went wrong</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const NAVIGATION_CARDS = [
  {
    title: "Welcome",
    image: "/projects/welcome-screenshot.png",
    desc: "",
    link: "/",
  },
  {
    title: "Audio Galaxy",
    image: "/projects/audio-galaxy-screenshot.png",
    desc: "",
    link: "galaxy",
  },
  {
    title: "Moon fox",
    image: "/projects/moon-fox-screenshot.png",
    desc: "",
    link: "target-morph",
  },
  {
    title: "Anime objects",
    image: "/projects/anime-objects.png",
    desc: "",

    link: "animatedObjects",
  },
];

function App() {
  const [hash, setHash] = useState("");


  const getHash = () =>
    typeof window !== "undefined"
      ? decodeURIComponent(window.location.hash.replace("#", ""))
      : undefined;

  useEffect(() => {
    const handleHashChange = () => {
      setHash(getHash());
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // TODO: Lazy load with an experience selector

  return (
    <div className="relative overflow-hidden flex h-full">
      <div className="navigator w-3/12 h-full flex flex-col overflow-y-scroll  bg-slate-500 gap-2 pr-2">
        {NAVIGATION_CARDS.map((props) => (
          <ProjectCard key={props.title} {...props} />
        ))}
      </div>

      <div className="wrapper content h-full">
        <Leva
          // hidden={isProd&& hash !== "debug"}
          collapsed={isProd}
        />
        <ErrorBoundary fallbackRender={FallBack}>
          <Outlet />
        </ErrorBoundary>
        {/* <AudioGraph /> */}
        {/* <ToolBar /> */}
      </div>
    </div>
  );
}

export default App;
