import { Canvas } from "@react-three/fiber";
import * as THREE from "three";

function DefaultCanvas({ children }) {
  const cameraSettings = {
    fov: 45,
    near: 0.1,
    far: 200,
    position: [0, 10, 10],
  };
  return (
    <Canvas
      camera={cameraSettings}
      gl={{
        antialias: true,
        toneMapping: THREE.ACESFilmicToneMapping,
        outputColorSpace: THREE.SRGBColorSpace,
      }}
    >
      {children}
    </Canvas>
  );
}

export default DefaultCanvas;
