import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const Center = forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div {...props} ref={ref} className={twMerge(className, 'min-h-screen justify-center items-center flex')}>
      {children}
    </div>
  )
})

Center.displayName = 'Center'

export default Center
