import { OrbitControls } from "@react-three/drei";
import DefaultCanvas from "../Canvases/DefaultCanvas";
import Content from "./Content";

function WelcomePage() {
  return (
    <DefaultCanvas>
     <OrbitControls makeDefault rotation={[-Math.PI / 2, 0, 0]} />
      <Content />
    </DefaultCanvas>
  );
}

export default WelcomePage;
